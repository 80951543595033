import recallServices from '../../../services/provider/recall'
import Communicationdetails from '../../share/communication-view/provider/communication-details'
import ProviderActions from '../../share/communication-view/provider/provider-actions'
import WorkflowActions from '../../share/WorkflowActions'
import ProviderCommunications from '../../share/communication-view/provider/provider-conversations'
import ReportDetails from '../../share/communication-view/provider/report-details'
import ResponseModal from '../../share/ResponseModal'
import ResponseEmailModal from '../../share/ResponseEmailModal'
import ChangeHistory from '../../share/communication-view/provider/change-history'
import router from '../../../router/paths'
import organizationService from '../../../services/provider/organization'
import { COMMUNICATION_TYPE } from '../../../misc/commons'
import { EMAIL_STATUS } from '../../../misc/commons'
export default {
    props: {
        id: {type: String, default: null},
        communicationId: {type: String, default: null},
        providerCommunicationId: {type: String, default: null},
        recallSiteCount: {type: Number, default: null},
        coveredStatus: {type: String,default: 'null'},
        communicationType: {type: String, default: null}
    },
    components: {
        Communicationdetails,
        ProviderActions,
        ProviderCommunications,
        WorkflowActions,
        ChangeHistory,
        ReportDetails,
        ResponseModal,
        ResponseEmailModal
    },
    data () {
        return {
            item: null,
            tabDetails: false,
            tabActions: false,
            tabCommunications: false,
            tabReports: false,
            tabHistory: false,
            tabWorkflow: false,
            isProviderCreatedRecall: false,
            iscustomisedcontent: false,
            respModalKey: 0,
            responseEmailKey: 0,
            responseDetails: null,
            type: null,
            disabledDelegate: false,
            completedRecall: false,
            SupplierContactlst: [],
            autonotifychecked: false,
            SupplierName: '',
            histories: [],
            updateNotificationCount: 0,
            isRedirected: false,
            is_centralized_setting_enabled: false,
            labels: {},
            emailStatus: EMAIL_STATUS,
            recall: COMMUNICATION_TYPE.RECALL,
            productdisruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
        }
    },
    created() {
        if (this.$route.query.conId && this.$route.query.conId > 0) {
            this.tabCommunications = true
        } else if(this.$route.query.tabName ==='tabDetails' ||(this.$route.query.nonAck && this.$route.query.nonAck !='')){
            this.tabDetails = true
        }
        else if(this.$route.query.tabName ==='tabHistory' ||(this.$route.query.logtype && this.$route.query.logtype !='')){
            this.tabHistory = true
        }
        else {
            this.tabDetails = true
        }
        this.is_centralized_setting_enabled = this.$store.state?.organization?.is_centralized_setting_enabled
        this.getRecallSummaryById()
        this.getAutoNotifySetting()
        this.GetRecallHistories()
    },
    computed: {
        isNonAcknowledged() {
            if (this.item.acknowledged_at == null && !this.item.is_provider_created_recall) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        GetRecallHistories() {
            var _this = this
            recallServices.GetRecallHistories(this.id, this.providerCommunicationId).then(resp => {
                if (!resp.error) {
                    this.histories = resp.data.d
                    if(_this.$route.query.logtype != undefined && !this.isRedirected)
                    {
                        if(_this.$route.query.logtype == 'RecallChangeLog')
                        {
                            this.isRedirected = true
                            this.updateNotificationCount = 0
                        }
                    }
                    else
                    {
                        this.updateNotificationCount = resp.data.d.filter(s => s.updatenotification == true).length
                    }
                }
            })
        },
        fetchSupplierContactList()
        {
            recallServices.findSupplierContacts(this.$store.state.orgId).then(resp => {
                if (!resp.error) {
                    this.SupplierContactlst = resp.data.d
                }
            })
        },
        getAutoNotifySetting()
        {
            organizationService.getAutoNotifySetting().then(resp => {
                if (!resp.error) {
                    this.autonotifychecked=resp.data.d.isautonotify
                    if(resp.data.d.isautonotify)
                    {
                        this.fetchSupplierContactList()
                    }
                }
            })
        },
        getRecallSummaryById() {
            var providerCommunicationId=this.$route.params.providerCommunicationId==undefined?0:parseInt(this.$route.params.providerCommunicationId)
            recallServices.getRecallSummaryById(this.id,providerCommunicationId.toString()).then(resp => {
                if (resp.error){
                    this.$router.push({ name: 'prov_Recall' })
                } else {
                    this.item = resp.data.d
                    if(this.item.completed_at != null)
                    {
                        this.completedRecall=true
                    }
                    if(this.item.providerEventStatus == 'DRAFT' || this.item.providerEventStatus == 'COMPLETED' || this.item.providerEventStatus == 'CLOSED' || this.item.completed_at != null)
                    {
                        this.disabledDelegate=true
                    }
                    else
                    {
                        this.disabledDelegate=false
                        this.completedRecall=false
                    }
                    this.disabledDelegate=false
                    this.isProviderCreatedRecall=resp.data.d.isProviderCreated
                    this.iscustomisedcontent = resp.data.d.is_customized_content
                    this.communicationType = resp.data.d.communicationType
                    this.labels={
                        details: resp.data.d.communicationType == this.recall ? 'Recall Details' : 'Advisory Details',
                        logs: resp.data.d.communicationType == this.recall ? 'Recall Logs' : 'Advisory Logs',
                    }
                    this.getRecallresponseDetails()
                }
            })
        },
        onLoad(event){
            this.type = event.toLowerCase()
        },
        onReLoadCommunicationData(){
            this.getRecallSummaryById()
        },
        onReLoadHistoryData(){
            this.GetRecallHistories()
        },
        onReLoad(event){
            this.getRecallresponseDetails()
        },
        getRecallresponseDetails() {
            recallServices.getRecallresponseDetails(this.id,this.providerCommunicationId).then(resp => {
                this.responseDetails = resp.data.d

            })
        },
        changeStatus () {
            if(this.item.communicationType == COMMUNICATION_TYPE.RECALL){
                this._showConfirm(this.item && this.item.completed_at ? 'Are you sure you want to mark this recall as open?' : 'Are you sure you want to mark this recall as complete?', {title: 'Recall', variant: 'warning'}).then(confirm => {
                    if (confirm) {
                        this.changeRecallStatus()
                    }
                })
            }
            if(this.item.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION){
                this._showConfirm(this.item && this.item.completed_at ? 'Are you sure you want to mark this supply advisory as open?' : 'Are you sure you want to mark this supply advisory as complete?', {title: 'Supply Advisory', variant: 'warning'}).then(confirm => {
                    if (confirm) {
                        this.changeRecallStatus()
                    }
                })
            }
        },
        acknowledge () {
            recallServices.acknowledgeRecall(this.id,this.providerCommunicationId).then(resp =>{
                if (!resp.error){
                    this.getRecallSummaryById()
                    this._showNotify('Acknowledge', this.item.communicationType == COMMUNICATION_TYPE.RECALL ? 'The recall has been acknowledged' :  'The advisory has been acknowledged' , null, {animationIcon: 'success', hideFooter: true, hideHeader: true})
                }
            })
        },
        downloadproviderAffectedLocationReport(extensionType) {
            recallServices.getRecallById(this.id,this.providerCommunicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'prov_Recall' })
                } else {
                    var isCentralizedSettingOn = this.$store.state?.organization?.is_centralized_setting_enabled
                    recallServices.downloadproviderAffectedLocationReport(this.id,isCentralizedSettingOn  ? 'EMAILED' :'COVERED',this.$store.state.user.id ? this.$store.state.user.id : -1,extensionType,resp.data.d.communicationUID,this.providerCommunicationId,this.item.recallsitename)
                }
            })
        },
        downloadproviderAffectedProductsReport(extensionType) {
            recallServices.getRecallById(this.id,this.providerCommunicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'prov_Recall' })
                } else {
                    recallServices.downloadproviderAffectedProductsReport(this.id,extensionType,resp.data.d.communicationUID,this.providerCommunicationId,this.item.recallsitename)
                }
            })
        },
        downloadCCReport(extensionType) {
            recallServices.getRecallById(this.id,this.providerCommunicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'prov_Recall' })
                } else {
                    recallServices.downloadCCReport(this.id,extensionType,resp.data.d.communicationUID,this.providerCommunicationId,this.item.recallsitename)
                }
            })
        },
        addMoreRespClick(item) {
            this.respModalKey += 1
            this.$nextTick(() => {
                this.$refs.respModal.forceRerenderInsert(item)
            })
        },
        onCompleted(event) {
            if (event) {
                this.responseDetails.responseEmail= this.$refs.responseEmailModal.ccvalueE.pop()
                //this.responseDetails.responseCCEmails= this.$refs.responseEmailModal.ccvalue
                this.responseDetails.additionalCcEmails= this.$refs.responseEmailModal.ccvalue
                this.responseDetails.supplierRepCCEmails= this.$refs.responseEmailModal.defaultEmailList.email
                this.$refs.responseEmailModal.$refs.modal.hide()
                this.$refs.responseEmailModal.response_email=null
                this.$refs.responseEmailModal.filesUpload=[]
                this.responseDetails.supplier_name = this.item.supplier_name
                recallServices.updateRecallResponseEmail(this.id,this.providerCommunicationId, this.responseDetails.responseEmail,event).then(resp=>{
                    if (!resp.error){
                        this.addMoreRespClick( this.responseDetails)
                    }
                })

            }
        },
        goToEditRecall() {
            localStorage.setItem('recallMode', 'EDIT')
            this.$router.push({
                name: router.prov_Recall_EditItem.name,
                params: { id: this.id ,providerCommunicationId: this.providerCommunicationId}
            })
        },
        respondToRecall(){
            this._showConfirm(this.item && this.item.completed_at ? 'Are you sure you want to mark this recall as open?' : 'Are you sure you want to mark this recall as complete?', {title: 'Recall', variant: 'warning'}).then(confirm => {
                if (confirm) {
                    if ((!this.responseDetails.is_response_document  && this.item.completed_at == null ) || (this.responseDetails.responseEmail === null  && this.item.completed_at == null)) {
                        if (this.responseDetails.responseEmail === null || this.responseDetails.responseEmail === '' || this.responseDetails.documents.length === 0 || this.responseDetails.status === 'BOUNCE') {
                            this.supplierModalKey += 1
                            this.$refs.responseEmailModal.response_email = this.responseDetails.responseEmail
                            this.$refs.responseEmailModal.item.affectedProduct = this.responseDetails.affectedProduct
                            this.$refs.responseEmailModal.item.affectedProductValue = this.responseDetails.affectedProductValue
                            this.$refs.responseEmailModal.filesUpload = this.responseDetails.documents.map(x => {
                                return {
                                    id: x.id,
                                    file: {
                                        name: x.name,
                                        type: x.type,
                                        size: x.size,
                                        extension: x.extension
                                    },
                                }
                            })
                            this.$nextTick(() => {
                                this.$refs.responseEmailModal.forceRerenderModel()
                            })
                        } else {
                            this.$nextTick(() => {
                                this.$refs.responseEmailModal.forceRerenderModel()
                            })
                            //this.addMoreRespClick(this.responseDetails)
                        }
                    }  else{
                        if(this.responseDetails.is_response_document && this.item.completed_at == null  && (this.responseDetails.responseEmail!=null || this.responseDetails.responseEmail != ''))
                        {
                            this.supplierModalKey += 1
                            this.$refs.responseEmailModal.response_email = this.responseDetails.responseEmail
                            this.$refs.responseEmailModal.item.affectedProduct = this.responseDetails.affectedProduct
                            this.$refs.responseEmailModal.item.affectedProductValue = this.responseDetails.affectedProductValue
                            this.$refs.responseEmailModal.filesUpload = this.responseDetails.documents.map(x => {
                                return {
                                    id: x.id,
                                    file: {
                                        name: x.name,
                                        type: x.type,
                                        size: x.size,
                                        extension: x.extension
                                    },
                                }
                            })
                            this.$nextTick(() => {
                                this.$refs.responseEmailModal.forceRerenderModel()
                            })
                            //this.addMoreRespClick(this.responseDetails)
                        }
                        else{
                            this.changeRecallStatus()
                        }
                    }

                }
            })
        },
        onSubmit(event) {
            if(event== 'yes'){
                this.changeRecallStatus()
            } else{
                this.getRecallSummaryById()

                this.$refs.communicationdetails.getRecallById()
            }
        },
        changeRecallStatus() {
            recallServices.changeRecallCompleteStatus(this.id,this.providerCommunicationId).then(resp => {
                if (!resp.error) {
                    if(this.item.communicationType == COMMUNICATION_TYPE.RECALL){
                        if (this.item.completed_at == null) {
                            this.disabledDelegate = true
                            this._showToast('Recall has been marked as complete!', { variant: 'success' })
                        } else {
                            this.disabledDelegate = false
                            this._showToast('Recall has been reopened!', { variant: 'success' })
                        }
                    }
                    if(this.item.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION){
                        if (this.item.completed_at == null || this.item.providerEventStatus == 'DRAFT') {
                            this.disabledDelegate = true
                            this._showToast('Supply advisory has been marked as complete!', { variant: 'success' })
                        } else {
                            this.disabledDelegate = false
                            this._showToast('Supply advisory has been reopened!', { variant: 'success' })
                        }
                    }
                    if(this.$refs.communicationdetails != undefined)
                    {
                        this.$refs.communicationdetails.getRecallById()
                    }
                    this.getRecallSummaryById()
                }
            })
        },
        sendNotification() {
            recallServices.sendNotification(this.id, this.communicationType, this.providerCommunicationId).then(resp => {
                if (!resp.error) {
                    this.getRecallSummaryById()
                    this.getCommunicationSummaryById()
                    if (this.tabDetails == true) {
                        this.tabDetailsKey += 1
                    }
                    this._showToast('Notification Sent Successfully!')
                    this.getCommunicationSummaryById()
                    this.$refs.communicationdetails.getRecallById()
                }
            })
        },
        getCommunicationSummaryById() {
            recallServices.getCommunicationSummaryById(this.id).then(resp => {
                if (resp.error){
                    this.$router.push({ name: 'prov_ProdDisruption' })
                } else {
                    this.item = resp.data.d
                    this.unreadMessages = this.item.unreadMessageCount
                    this.dependentDisabledDates.to = this.item.disruptionUpdateDate ? new Date(new Date(this.item.disruptionUpdateDate.split('T')[0]).getFullYear(), new Date(this.item.disruptionUpdateDate.split(' ')[0]).getMonth(), (new Date(this.item.disruptionUpdateDate.split(' ')[0]).getDate())) : new Date(this.item.communicationDate)
                    if(this.item.status == 'CLOSED' || this.item.providerEventStatus == 'DRAFT' || this.item.providerEventStatus == 'COMPLETED')
                    {
                        this.disabledDelegate=true
                    }
                    else
                    {
                        this.disabledDelegate=false
                    }
                }
            })
        },
    }
}